<template>
  <div class="page-main">
    <h1>{{ $t("connexion.reset") }}</h1>

    <div class="topPageMenu">
      <router-link :to="{ name: 'connexion' }">
        {{ $t("cancel") }}
      </router-link>
    </div>

    <p class="help">
      {{ $t("connexion.forgot") }}
    </p>

    <form id="reset" method="POST" @submit="validate">
      <fieldset>
        <GenericInput
          :label="$t('fin.label')"
          v-model.trim="fin"
          type="text"
          :help="$t('fin.desc')"
          :required="true"
          :finValidator="true"
          :forceErrorMsg="finRemoteError"
          @error="checkError"
          :refeshError="refeshError"
        />
        <Captcha
          v-model="captcha"
          :captcha="captcha"
          :refresh="refreshCaptcha"
          @refreshed="refreshedCaptcha"
          @error="checkError"
          :refeshError="refeshError"
        />
      </fieldset>
      <button class="primaryAction" type="submit">
        {{ $t("connexion.resetaction") }}
      </button>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import GenericInput from "@/components/GenericInput.vue";
import Captcha from "@/components/Captcha.vue";

export default {
  name: "connexion-reset",
  computed: {},
  components: {
    GenericInput,
    Captcha
  },
  data() {
    return {
      fin: null,
      captcha: {
        text: null,
        id: null
      },
      error: false,
      refeshError: false,
      refreshCaptcha: false,
      finRemoteError: null
    };
  },
  methods: {
    ...mapActions("api", ["resetPassword"]),
    checkError(value) {
      this.error = { ...this.error, ...value };
    },
    refreshedCaptcha() {
      this.refreshCaptcha = false;
    },
    validate: function(e) {
      e.preventDefault();
      const vuejs = this;
      const fin = vuejs.fin;
      const captcha = vuejs.captcha;

      this.refeshError = !this.refeshError;
      vuejs.$nextTick(() => {
        if (vuejs.$hasInputErrorAndScroll()) {
          return;
        }
        vuejs.$store
          .dispatch("showLoadingOverlay")
          .then(() => vuejs.resetPassword({ fin: fin, captcha: captcha }))
          .then(() =>
            vuejs.$router
              .replace({ name: "connexionresetmail" })
              .catch(error => {
                // ignore because of exception on navigation guard...
              })
          )
          .catch(error => {
            this.refreshCaptcha = true;
            if (
              error.errorType == "user" &&
              error.errors?.hasOwnProperty("fin")
            ) {
              this.finRemoteError = error.errors.fin[0];
              this.refeshError = !this.refeshError;
            } else {
              vuejs.$alertUserApiError(error);
            }
          })
          .finally(() => vuejs.$store.dispatch("hideLoadingOverlay"));
      });
    }
  },
  watch: {
    "captcha.text"(newVal) {
      this.finRemoteError = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.topPageMenu {
  color: $darkColor;
  padding-bottom: 25px;
}
.topPageMenu a {
  color: $darkColor;
  display: block;
  text-align: center;
  padding: 7px;
}
.topPageMenu a:hover {
  text-decoration: underline;
}
.topPageMenu .separator {
  display: none;
}
p.help {
  text-align: justify;
  margin-top: 5px;
  margin-bottom: 25px;
  @media screen and (max-width: 514.9px) {
    margin-left: 3%;
    margin-right: 3%;
  }
}
</style>
